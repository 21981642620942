import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        zointeg: {
            zointeg_id: '',
            zointeg_nama: '',
            zointeg_ket: '',
            zointeg_file: '',
            zointeg_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.zointeg = value
        }
    }
})
